import React from "react";
import logo from "../../images/logo.png";
import logo2 from "../../images/logo2.png";
import facebook from "../../images/facebook.png";
import x from "../../images/x.png";
import instagram from "../../images/instagram.png";
import linkedin from "../../images/linkedin.png";
import { Link } from "react-scroll";
import { Link as LinkTo} from "react-router-dom";
import "../../App.css";

const Layout = ({ children }) => {
  return (
    <div className="container-layout">
      <div className=""></div>
      <div className="children">{children}</div>
      <div className="footer-principal-container">
        <div className="footer-container">
          <div className="footer-section">
            <div className="footer-section1">
              <img src={logo2} alt="logo2-softsmart" />
              <div className="footer-section1-text">
                Creating world-class user experiences across <br />
                web, ecommerce, and mobile platforms.
              </div>
              <div className="footer-section1-img">
                <LinkTo to="https://www.facebook.com/profile.php?id=61558168022804" target="_blank">
                <img src={facebook} alt="facebook" />
                </LinkTo>
                <img src={x} alt="x" />
                <img src={instagram} alt="instagram" />
                <LinkTo to="https://www.linkedin.com/company/softsmart-2-0-inc/about/?viewAsMember=true" target="_blank">
                <img src={linkedin} alt="linkedin" />
                </LinkTo>
              </div>
            </div>
            <div className="footer-section2">
              <Link
                activeClass="no-active"
                to="contact-us"
                spy={true}
                smooth={true}
                offset={20}
                duration={100}
                style={{textDecoration:"none"}}
              >
                <div className="contact-us-header">Contact Us</div>
              </Link>
            </div>
          </div>
          <div className="footer-inc">
            © 2024 Softsmart Inc 2.0 | All Rights Reserved
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
