import React, { useEffect, useRef } from "react";
import "../../App.css";
import Layout from "./../../components/Layout/Layout";
import computer from "../../images/computer.png";
import computerVideo from "../../videos/team.mp4";
import circle from "../../images/circle1.png";
import circleLeft from "../../images/circle2.png";
import circleRight from "../../images/circle3.png";
import circleRight2 from "../../images/circle4.png";
import circleLeft2 from "../../images/circle5.png";
import circleBottom from "../../images/circle6.png";
import group from "../../images/Group.png";
import circleLogo from "../../images/circle-logo.png";
import left1 from "../../images/left1.png";
import phone from "../../images/phone.png";
import data from "../../images/data.png";
import testing from "../../images/testing.png";
import crm from "../../images/crm.png";
import line1 from "../../images/line1.png";
import line2 from "../../images/line2.png";
import line3 from "../../images/line3.png";
import line4 from "../../images/line4.png";
import line5 from "../../images/line5.png";
import subline1 from "../../images/subline1.png";
import subline2 from "../../images/subline2.png";
import subline3 from "../../images/subline3.png";
import subline4 from "../../images/subline4.png";
import subline5 from "../../images/subline5.png";
import joseph from "../../images/joseph.png";
import miguel from "../../images/miguel.png";
import joseDaniel from "../../images/joseDaniel.png";
import pablo2 from "../../images/pablo2.png";
import hugo from "../../images/hugo.png";
import juanPablo from "../../images/JuanPablo.png";
import ishtiaq from "../../images/ishtiaq.png";
import flor from "../../images/flor.png";
import letsConnect from "../../images/letsConnect.png";
import finalcial from "../../images/financialPartners.png";
import confidentialBee from "../../images/confidentialBee.png";
import lendsys from "../../images/lendsys.png";
import tanach from "../../images/tanach.png";
import altaOne from "../../images/altaOne.png";
import team from "../../images/teamAwesomism.png";
import foco from "../../images/foco.png";
import arrow from "../../images/arrow.png";
import process from "../../images/process.png";
import logo from "../../images/logo.png";
import services from "../../images/services2.png";
import { Link } from "react-scroll";
import { useState } from "react";
import { toast } from "react-toastify";
import { Link as LinkTo} from "react-router-dom";
import ashish from "../../images/ashish.png"
import victor from "../../images/victor.png"
import waqas from "../../images/waqas.png"
import khan from "../../images/khan.png"
import panchal from "../../images/panchal.png"

const Homepage = () => {
  const [formulario, setFormulario] = useState({
    firstname: "",
    lastname: "",
    email: "",
    comments: "",
  });
  const videoRef = useRef(null);

  const form = {
    firstname: "",
    lastname: "",
    email: "",
    comments: "",
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormulario({ ...formulario, [name]: value });
  };

  const sendEmail = () => {
    const { firstname, lastname, email, comments } = formulario;
    const myHeaders = new Headers();
    myHeaders.append("AppName", "DbTanach");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer 14FE34B2-9547-43F5-A57C-F0DC7DE81AA9"
    );

    const raw = JSON.stringify({
      Subject: "Softsmart 2.0 Information",
      Email: "info@softsmartcorp.com",
      EmailTo: "info@softsmartcorp.com",
      Body: `The user ${firstname} ${lastname} whith email ${email} ask about the following information: ${comments}`,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://api.softsmartinc.com/Services/v2/Widget/Email/Send",
      requestOptions
    )
      .then((response) => {
        if (response.ok) {
          setFormulario(form);
          toast.success("The email was sent successfully.");
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        } else {
          toast.warning("There was an error sending the email.");
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error("There was an error sending the email.");
      });
  };

  useEffect(() => {
    const video = videoRef.current;

    const handleEnded = () => {
      video.play();
    };

    video.addEventListener('ended', handleEnded);

    return () => {
      video.removeEventListener('ended', handleEnded);
    };
  }, []);

  return (
    <div className="homepage-principal-container">
      <Layout>
        <div className="header-principal-container">
          <div className="header-container">
            <div>
              <img src={logo} alt="logo-softsmart" />
            </div>
            <Link
              activeClass="active"
              to="contact-us"
              spy={true}
              smooth={true}
              offset={20}
              duration={100}
            >
              <div className="contact-us-header">Contact Us</div>
            </Link>
          </div>
        </div>
        <div className="principal-hero-section">
          <div className="hero-section">
            <div className="hero-section-subtitle">
              Custom Software Development
            </div>
            <h1>Discover the Future of Financial Technology</h1>
            <p>
              At SoftSmart 2.0, we're dedicated to revolutionizing the way
              financial institutions leverage technology to thrive in today's
              competitive market.
            </p>
            <p>
              {" "}
              From precision automation testing to tailor-made CRM solutions,
              dynamic data dashboards, seamless mobile app experiences, and
              efficient nearshore development, we provide comprehensive software
              and app development services designed to meet the unique needs of
              the financial sector.{" "}
            </p>
          </div>
        </div>
        <div className="principal-subHero-section">
          <div className="subHero-section">
            Explore our expertise and unlock new possibilities for your
            business.
          </div>
        </div>
        <div className="principal-section1">
          <div className="section1">
            <div className="section-about-us">
              <div className="aboout-us-title">About Us</div>
              <div className="green-line-container">
                <div className="green-line"></div>
              </div>
              <div className="who-we-are">Who are we?</div>
              <p className="section-first">
                Softsmart Systems Int. Inc. provides the highest level of custom
                software and website development services. With over 25 years
                of experience, Softsmart develops solutions that addresses
                growing technology needs in today’s consumer driven marketplace.{" "}
              </p>
              <p className="section-second">
                Our team of programmers, technicians, product and business
                developers have the expertise to understand your business needs
                and implement the right custom software solutions to help your
                business grow and service your customers.
              </p>
            </div>
            <div className="section-computer">
              <img src={computer} alt="computer" />
              <video className="computer-video" ref={videoRef} autoPlay muted>
                <source src={computerVideo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
        <div className="principal-section2">
          {/* <div className="chart">
            <p className="chart__text" style={{ color: "#e25444" }}>
              HTML 30%
            </p>
            <p className="chart__text" style={{ color: "#01a7b7" }}>
              CSS 20%
            </p>
            <p className="chart__text" style={{ color: "#ffaa22" }}>
              Javascript 25%
            </p>
            <p className="chart__text" style={{ color: "#77aa88" }}>
              Vue 15%
            </p>
            <p className="chart__text" style={{ color: "#88aacc" }}>
              React 10%
            </p>
            <div className="chart__pie"></div>
          </div> */}
          <div className="circle-title">
            <div className="services-title-container">
              <div className="services-title">Services</div>
            </div>
            <div className="services-sub-title">
              Expertise you can depend on
            </div>
            <div className="services-sub-text">
              When you choose Softsmart2.0, you can trust that you're getting
              the absolute best. Our unwaveringdedication to professionalism and
              quality ensures that your project is incapable hands from start to
              finish.
            </div>
          </div>
          <div className="circle-container desktop">
            <div className="circle-subcontainer">
              <div className="img1">
                <img src={circle} alt="" />
                <img src={circleLogo} className="circle-logo" alt="" />
              </div>
              <div className="img2">
                <img src={circleLeft} alt="" />
                <img src={left1} className="left1" alt="" />
              </div>
              <div className="img3">
                <img src={circleRight} alt="" />
                <img src={phone} className="phone" alt="" />
              </div>
              <div className="img4">
                <img src={circleLeft2} alt="" />
                <img src={testing} alt="" className="testing" />
              </div>
              <div className="img5">
                <img src={circleRight2} alt="" />
                <img src={data} alt="" className="data" />
              </div>
              <div className="img6">
                <img src={circleBottom} alt="" />
                <img src={crm} alt="" className="crm" />
              </div>
              <div className="group">
                <img src={group} alt="" />
                <div className="circle-text">
                  OUR <br />
                  SERVICES
                </div>
              </div>

              <div className="line1">
                <img src={line1} alt="" className="line1src" />
              </div>

              <div className="line2">
                <img src={line2} alt="" className="line2src" />
              </div>

              <div className="line3">
                <img src={line3} alt="" className="line3src" />
              </div>

              <div className="line4">
                <img src={line4} alt="" className="line4src" />
              </div>

              <div className="line5">
                <img src={line5} alt="" className="line5src" />
              </div>

              <div className="subline1">
                <img src={subline1} alt="" />
                <div className="text-title1">Nearshore Development:</div>
                <div className="text-content1">
                  Optimize costs and collaboration with our efficient nearshore
                  development solutions.
                </div>
              </div>
              <div className="subline2">
                <img src={subline2} alt="" />
                <div className="text-title2">Mobile App Production:</div>
                <div className="text-content2">
                  Deliver seamless user experiences with our expert mobile app
                  development services.
                </div>
              </div>
              <div className="subline3">
                <img src={subline3} alt="" />
                <div className="text-title3">Custom Data Dashboard:</div>
                <div className="text-content3">
                  Gain actionable insights with our dynamic custom data
                  dashboard solutions.
                </div>
              </div>
              <div className="subline4">
                <img src={subline4} alt="" />
                <div className="text-title4">Automation Testing:</div>
                <div className="text-content4">
                  Ensure flawless performance with our precision automation
                  testing solutions.
                </div>
              </div>
              <div className="subline5">
                <img src={subline5} alt="" />
                <div className="text-title5">
                  Custom CRM:
                  <span className="text-subtitle5">
                    (Customer Relationship Management)
                  </span>
                </div>
                <div className="text-content5">
                  Empower your business with a tailor-made CRM solution for
                  personalized client relationships.
                </div>
              </div>
            </div>
          </div>
          <div className="mobile">
            <div className="mobile-container">
              <img src={services} alt="" />
            </div>
            <div>
              <div className="text-title1">Nearshore Development:</div>
              <div className="text-content1">
                Optimize costs and collaboration with our efficient nearshore
                development solutions.
              </div>
            </div>
            <div>
              <div className="text-title2">Mobile App Production:</div>
              <div className="text-content2">
                Deliver seamless user experiences with our expert mobile app
                development services.
              </div>
            </div>
            <div>
              <div className="text-title3">Custom Data Dashboard:</div>
              <div className="text-content3">
                Gain actionable insights with our dynamic custom data dashboard
                solutions.
              </div>
            </div>
            <div>
              <div className="text-title4">Automation Testing:</div>
              <div className="text-content4">
                Ensure flawless performance with our precision automation
                testing solutions.
              </div>
            </div>
            <div>
              <div className="text-title5">
                Custom CRM:
                <span className="text-subtitle5">
                  (Customer Relationship Management)
                </span>
              </div>
              <div className="text-content5">
                Empower your business with a tailor-made CRM solution for
                personalized client relationships.
              </div>
            </div>
          </div>
        </div>
        <div className="principal-section3">
          <div className="container-section3">
            <div className="section3-title">How We Work</div>
            <div className="section3-subtitle">Meet Our Team</div>
            <p className="section3-text">
              When you choose Softsmart2.0, you can trust that you're getting
              the absolute best. Our unwaveringdedication to professionalism and
              quality ensures that your project is incapable hands from start to
              finish.
            </p>
            <div className="section3-photos">
              <div className="info-container">
                <div className="section-photo">
                  <img src={joseph} alt="Joseph Cohen" />
                </div>
                <div className="info-name">Joseph Cohen</div>
                <div className="info-position">Technologist</div>
              </div>
              <div className="info-container">
                <div className="section-photo">
                  <img src={miguel} alt="Miguel Montenegro" />
                </div>
                <div className="info-name">Miguel Montenegro</div>
                <div className="info-position">Marketing Supervisor</div>
              </div>
              {/* <div className="info-container">
                <div className="section-photo">
                  <img src={joseDaniel} alt="Jose Daniel B." />
                </div>
                <div className="info-name">Jose Daniel B.</div>
                <div className="info-position">Developer</div>
              </div> */}
              <div className="info-container">
                <div className="section-photo">
                  <img src={pablo2} alt="Pablo Brito" />
                </div>
                <div className="info-name">Pablo Brito</div>
                <div className="info-position">Mobile Developer</div>
              </div>
              <div className="info-container">
                <div className="section-photo">
                  <img src={hugo} alt="Hugo Tapia" />
                </div>
                <div className="info-name">Hugo Tapia</div>
                <div className="info-position">Front End Developer</div>
              </div>
              <div className="info-container">
                <div className="section-photo">
                  <img src={juanPablo} alt="Juan Pablo Rodriguez" />
                </div>
                <div className="info-name">Juan Pablo Rodriguez</div>
                <div className="info-position">UI/UX Designer</div>
              </div>
              <div className="info-container">
                <div className="section-photo">
                  <img src={ishtiaq} alt="Ishtiaq Ahmed" />
                </div>
                <div className="info-name">Ishtiaq Ahmed</div>
                <div className="info-position">Lead Engineer</div>
              </div>
              <div className="info-container">
                <div className="section-photo">
                  <img src={ashish} alt="Ashish Christian" style={{objectFit:"cover"}}/>
                </div>
                <div className="info-name">Ashish Christian</div>
                <div className="info-position">DevOps Engineer</div>
              </div>
              <div className="info-container">
                <div className="section-photo">
                  <img src={flor} alt="Flor Davila" />
                </div>
                <div className="info-name">Flor Davila</div>
                <div className="info-position">QA/Project Management</div>
              </div>
              <div className="info-container">
              <div className="section-photo">
                  <img src={victor} alt="Victor Argandona" style={{objectFit:"cover"}}/>
                </div>
                <div className="info-name">Victor Argandona</div>
                <div className="info-position">Back End Developer</div>
              </div>
              <div className="info-container">
              <div className="section-photo">
                  <img src={waqas} alt="Waqas" style={{objectFit:"cover"}}/>
                </div>
                <div className="info-name">Muhammad Waqas</div>
                <div className="info-position">Banking Back End Developer</div>
              </div>
              <div className="info-container">
              <div className="section-photo">
                  <img src={khan} alt="Matiuhal" style={{objectFit:"cover"}}/>
                </div>
                <div className="info-name">Matiullah Khan</div>
                <div className="info-position">Back End Architect</div>
              </div>
              <div className="info-container">
              <div className="section-photo">
                  <img src={panchal} alt="Nirav Panchal" style={{objectFit:"cover"}}/>
                </div>
                <div className="info-name">Nirav Panchal</div>
                <div className="info-position">Automation Testing</div>
              </div>
            </div>
          </div>
        </div>
        <div className="principal-section4">
          <div className="container-section4">
            <LinkTo to="https://letsconnect123.com/" target="_blank">
            <img src={letsConnect} alt="letsConnect" />
            </LinkTo>
            <LinkTo to="https://confidentialbee.com/" target="_blank">
            <img src={confidentialBee} alt="confidentialBee" />
            </LinkTo>
            <LinkTo to="https://fpcu.org/" target="_blank">
            <img src={finalcial} alt="finalcial" />
            </LinkTo>
            <LinkTo to="https://cule.lendsys.com/cule/login.aspx" target="_blank">
            <img src={lendsys} alt="lendsys" />
            </LinkTo>
            <LinkTo to="https://www.nachnook.com/" target="_blank">
            <img src={tanach} alt="tanach" />
            </LinkTo>
            <LinkTo to="https://altaone.org/" target="_blank">
            <img src={altaOne} alt="altaOne" />
            </LinkTo>
            <LinkTo to="https://teamawesomism.com/" target="_blank">
            <img src={team} alt="team" />
            </LinkTo>
          </div>
        </div>
        <div className="principal-section5">
          <div className="container-section5">
            <img src={foco} alt="foco" />
            <div className="letsTalk-title">
              Lets talk business <br /> Tell us about your project and budget
            </div>
          </div>
        </div>
        <div className="principal-section6">
          <div className="container-section6">
            <div className="title-section6">Why you should choose us</div>
            <div className="subtitle-section6">We are a digital agency </div>
            <p className="text-section6">
              Creating world-className user experiences across web, ecommerce,
              and mobile platforms.
            </p>
            <div className="digital-container">
              <div className="digital-photo">
                <img src={process} alt="process" />
              </div>
              <div className="digital-step">
                <div className="step">
                  <div className="img-arrow">
                    <img src={arrow} alt="arrow" />
                  </div>
                  <div className="step-text">
                    <div className="step-subtitle">STEP 1</div>
                    <div className="step-title">Meet</div>
                    <div className="step-text">
                      To find the best fit solution for your digital challenges,
                      we get to know your business, its goals and aspirations.
                    </div>
                  </div>
                </div>
                <div className="step">
                  <div className="img-arrow">
                    <img src={arrow} alt="arrow" />
                  </div>
                  <div className="step-text">
                    <div className="step-subtitle">STEP 2</div>
                    <div className="step-title">Design</div>
                    <div className="step-text">
                      We tailor a plan of action to your requirements, testing
                      all the possibilities to ensure the best course of action.
                    </div>
                  </div>
                </div>
                <div className="step">
                  <div className="img-arrow">
                    <img src={arrow} alt="arrow" />
                  </div>
                  <div className="step-text">
                    <div className="step-subtitle">STEP 3</div>
                    <div className="step-title">Build</div>
                    <div className="step-text">
                      With a clear design objective in place, we develop a
                      website using the latest technologies to deliver the very
                      best results.
                    </div>
                  </div>
                </div>
                <div className="step">
                  <div className="img-arrow">
                    <img src={arrow} alt="arrow" />
                  </div>
                  <div className="step-text">
                    <div className="step-subtitle">STEP 4</div>
                    <div className="step-title">Launch</div>
                    <div className="step-text">
                      Our testing ensures a smooth, problem-free launch,
                      presenting an engaging, user-friendly website your
                      customers will love.
                    </div>
                  </div>
                </div>
                <div className="step">
                  <div className="img-arrow">
                    <img src={arrow} alt="arrow" />
                  </div>
                  <div className="step-text">
                    <div className="step-subtitle">STEP 5</div>
                    <div className="step-title">Market</div>
                    <div className="step-text">
                      To ensure success, we offer an effective digital strategy
                      that will bring visitors to your website & increase your
                      ROI.
                    </div>
                  </div>
                </div>

                <div></div>
              </div>
            </div>
          </div>
        </div>
        <div className="principal-section7" id="contact-us">
          <div className="container-section7">
            <div className="form-contact">
              <div className="form-subtitle">Contact Us</div>
              <div className="form-title">Get in touch</div>
              <p className="form-text">
                We'd love to hear from you. Please provide us with your
                preferred <br />
                contact method so we can be sure to reach you.
              </p>
              <div className="form-container">
                <div
                  className="mb-4"
                  style={{
                    display: "flex",
                    width: "100%",
                    gap: "10px",
                  }}
                >
                  <input
                    type="text"
                    className="form-control "
                    name="firstname"
                    onChange={handleChange}
                    style={{ padding: "10px", height: "52px" }}
                    id="inputFirstname"
                    placeholder="First Name"
                  />

                  <input
                    type="text"
                    className="form-control "
                    name="lastname"
                    onChange={handleChange}
                    style={{ padding: "10px", height: "52px" }}
                    id="inputLastname"
                    placeholder="Last Name"
                  />
                </div>
                <div
                  className="mb-4"
                  style={{
                    display: "flex",
                    width: "100%",
                    gap: "10px",
                  }}
                >
                  <input
                    type="text"
                    className="form-control "
                    name="email"
                    onChange={handleChange}
                    style={{ padding: "10px", height: "52px" }}
                    id="inputEmail"
                    placeholder="Email"
                  />
                </div>
                <div className="form-group">
                  <textarea
                    name="comments"
                    className="form-control"
                    onChange={handleChange}
                    id="exampleTextarea"
                    style={{ height: "250px", maxHeight: "300px" }}
                    placeholder="Comments"
                  ></textarea>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-12">
                  <div className="form-check checkbox-container">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="terms-checkbox"
                    />
                    <label
                      className="form-check-label label-info"
                      htmlFor="terms-checkbox"
                    >
                      I have read and accepted the Terms & Conditions and
                      Privacy Policy.*
                    </label>
                  </div>
                </div>
              </div>
              <div className="button-container">
                <div
                  className="button-form"
                  onClick={() => {
                    // console.log("Form",formulario)
                    sendEmail();
                  }}
                >
                  Submit Now
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default Homepage;
