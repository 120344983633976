import React, { useState } from "react";
import Layout from "../../components/Layout/Layout";
import { Link } from "react-scroll";
import logo from "../../images/logo.png";
import arrow from "../../images/arrow2.png";
import info from "../../images/info.png";
import { FaRegCircleXmark } from "react-icons/fa6";
import "../../App.css";

const Support = () => {
	const [file, setFile] = useState(null);
	const [formulario, setFormulario] = useState({
		firstname: "",
		lastname: "",
		email: "",
		comments: "",
	});
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormulario({ ...formulario, [name]: value });
	};
	const handleDragOver = (e) => {
		e.preventDefault();
	};
	const handleDrop = (e) => {
		e.preventDefault();
		const file = e.dataTransfer.files[0];
		setFile(file);
	};
	return (
		<div className="homepage-principal-container">
			<Layout>
				<div className="header-principal-container">
					<div className="header-container">
						<div>
							<img src={logo} alt="logo-softsmart" />
						</div>
						<Link
							activeClass="active"
							to="contact-us"
							spy={true}
							smooth={true}
							offset={20}
							duration={100}
						>
							<div className="contact-us-header">Contact Us</div>
						</Link>
					</div>
				</div>
				<div className="principal-hero-section2">
					<div className="hero-section">
						<div className="hero-section-subtitle">
							Ticketing System
						</div>
						<h1>Contact Support</h1>
						<p>
							It is a long established fact that a reader will be
							distracted by the readable content of a page when
							looking at its layout. The point of using Lorem
							Ipsum is that it has a more-or-less normal
							distribution of letters, as opposed to using
							'Content here, content here', making it look like
							readable English.
						</p>
					</div>
				</div>
				<div className="suport-section">
					<div className="suport-section-container">
						<div className="suport-title">
							If applicable, please type in or attach below:
						</div>
						<div className="arrow-container">
							<div className="arrow-subcontainer">
								<div className="arrow-content">
									<img src={arrow} alt="arrow" />
									<div>Any screenshots</div>
								</div>
								<div className="arrow-content">
									<img src={arrow} alt="arrow" />
									<div>Error messages</div>
								</div>
							</div>
							<div className="arrow-subcontainer">
								<div className="arrow-content">
									<img src={arrow} alt="arrow" />
									<div>Reproduction steps</div>
								</div>
								<div className="arrow-content">
									<img src={arrow} alt="arrow" />
									<div>Minimal reproduction of the issue</div>
								</div>
							</div>
						</div>
						<div className="container-note">
							<div className="info-note-container">
								<img src={info} alt="info" />
								<div className="info-note">
									<span className="note">Note: </span> All the
									Lorem Ipsum generators on the Internet tend
									to repeat predefined chunks as necessary,
									making this the first true generator on the
									Internet.
								</div>
							</div>
							<div className="info-note-containe2">
								<div className="info-note">
									There are many variations of passages of
									Lorem Ipsum available, but the majority have
									suffered alteration in some form, by
									injected humour, or randomised words which
									don't look even slightly believable. If you
									are going to use a passage of Lorem Ipsum.
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="principal-section8" id="contact-us">
					<div className="container-section7">
						<div className="form-contact">
							<div className="form-subtitle">Ticketing System</div>
							<div className="form-title">Contact Support</div>
							<p className="form-text">
              There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration in some form
							</p>
							<div className="form-container">
								<div
									className="mb-4"
									style={{
										display: "flex",
										width: "100%",
										gap: "10px",
									}}
								>
									<input
										type="text"
										className="form-control "
										name="firstname"
										onChange={handleChange}
										style={{
											padding: "10px",
											height: "52px",
										}}
										id="inputFirstname"
										placeholder="First Name"
									/>

									<input
										type="text"
										className="form-control "
										name="lastname"
										onChange={handleChange}
										style={{
											padding: "10px",
											height: "52px",
										}}
										id="inputLastname"
										placeholder="Last Name"
									/>
								</div>
								<div
									className="mb-4"
									style={{
										display: "flex",
										width: "100%",
										gap: "10px",
									}}
								>
									<input
										type="text"
										className="form-control "
										name="email"
										onChange={handleChange}
										style={{
											padding: "10px",
											height: "52px",
										}}
										id="inputEmail"
										placeholder="Email"
									/>
								</div>
								<div
									className="mb-4"
									style={{
										display: "flex",
										width: "100%",
										gap: "10px",
									}}
								>
									<select
										className="form-select"
										name="category"
										onChange={handleChange}
										style={{
											padding: "10px 10px",
											height: "52px",
										}}
										id="category"
										placeholder="Select a Category"
									>
										<option
											style={{ padding: "20px 10px" }}
										>
											Select a Category
										</option>
									</select>
								</div>
								<div
									className="mb-4"
									style={{
										display: "flex",
										width: "100%",
										gap: "10px",
									}}
								>
									<select
										className="form-select"
										name="product"
										onChange={handleChange}
										style={{
											padding: "10px 10px",
											height: "52px",
										}}
										id="product"
										placeholder="Select Product"
									>
										<option
											style={{ padding: "20px 10px" }}
										>
											Select Product
										</option>
									</select>
								</div>
								<div className="row mt-1">
									<div className="col-12">
										<div className="checkbox-container">
											<div className="mt-0 mb-1">
												<span className="urgency-title">
													Urgency
												</span>
											</div>
											<div className="form-check mb-1">
												<input
													className="form-check-input"
													type="radio"
													name="flexRadioDefault"
													id="flexRadioDefault1"
												/>
												<label
													className="form-check-label"
													for="flexRadioDefault1"
												>
													My live app is suddenly
													broken and is severely
													affecting my users
												</label>
											</div>
											<div className="form-check mb-1">
												<input
													className="form-check-input"
													type="radio"
													name="flexRadioDefault"
													id="flexRadioDefault2"
													checked
												/>
												<label
													className="form-check-label"
													for="flexRadioDefault2"
												>
													I have a minor bug,
													question, or feature request
												</label>
											</div>
											<div className="form-check mb-3">
												<input
													className="form-check-input"
													type="radio"
													name="flexRadioDefault"
													id="flexRadioDefault2"
													checked
												/>
												<label
													className="form-check-label"
													for="flexRadioDefault2"
												>
													I have an account or billing
													issue I need help with
												</label>
											</div>
										</div>
									</div>
								</div>
								<div
									className="mt-1 mb-4"
									style={{
										display: "flex",
										width: "100%",
										gap: "10px",
									}}
								>
									<input
										type="text"
										className="form-control "
										name="sumary"
										onChange={handleChange}
										style={{
											padding: "10px",
											height: "52px",
										}}
										id="sumary"
										placeholder="Sumary"
									/>
								</div>
								<div className="form-group">
									<textarea
										name="comments"
										className="form-control"
										onChange={handleChange}
										id="exampleTextarea"
										style={{
											height: "250px",
											maxHeight: "300px",
										}}
										placeholder="Comments"
									></textarea>
								</div>
								<div>
									<div className="mt-3 mb-1">
										<span className="urgency-title">
											Attach Files
										</span>
										<span className="allowed-files">
											{" "}
											(allowed file types: .jpg .jpeg
											.json .gif .pdf .png .txt .xml)
										</span>
									</div>
									<div className="drag-and-drop-container">
										<div
											className="drag-1"
											onDragOver={handleDragOver}
											onDrop={handleDrop}
										>
											<div
												className="urgency-title"
												style={{ textAlign: "center" }}
											>
												Drag and Drop
											</div>
											<div className="sub-drag-1">
												<span>a file here or</span>
												<label
													htmlFor="file"
													className="choose-file-button"
												>
													{" "}
													Choose Files
												</label>
												<input
													type="file"
													id="file"
													name="file"
													style={{ display: "none" }}
													onChange={(e) => {
														setFile(
															e.target.files[0]
														);
													}}
												/>
											</div>
										</div>
										<div className="drag-2">
											<div className="close-button">
												{!file ? (
													<div>
														will not treat any
														information.jpg
													</div>
												) : (
													<div>{file.name}</div>
												)}
												<FaRegCircleXmark
													color="#6daf48"
													size={25}
													onClick={() => {
														setFile(null);
													}}
													style={{
														cursor: "pointer",
													}}
												/>
											</div>
										</div>
									</div>
								</div>
                <div className="submit-info">
                By submitting this information, you give us permission to contact you. Do not use this form to transmit confidential, sensitive, or personally identifying information that belongs to you or third parties, including but not limited to: passwords, credit card numbers, and user data. Please understand that Google will not treat any information you submit - other than your account information - as confidential.
                </div>
							</div>
							<div className="button-container">
              <div
									className="button-form-cancel"
									onClick={() => {
										// console.log("Form",formulario)
									}}
								>
									Cancel
								</div>
								<div
									className="button-form"
									onClick={() => {
										// console.log("Form",formulario)
									}}
								>
									Submit Now
								</div>
							</div>
						</div>
					</div>
				</div>
			</Layout>
		</div>
	);
};

export default Support;
