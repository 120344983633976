import React, { useState } from "react";
import Layout from "../../components/Layout/Layout";
import { Link } from "react-scroll";
import { Link as LinkTo } from "react-router-dom";
import logo from "../../images/logo.png";
import arrow from "../../images/arrow2.png";
import info from "../../images/info.png";
import { FaRegCircleXmark } from "react-icons/fa6";
import "../../App.css";

const About = () => {
	
	return (
		<div className="homepage-principal-container">
			<Layout>
				<div className="header-principal-container">
					<div className="header-container">
						<div>
							<img src={logo} alt="logo-softsmart" />
						</div>
					
					</div>
				</div>
				<div className="principal-hero-section3">
					<div className="hero-section">
						<h1>About</h1>
					</div>
				</div>
                 <div className="principal-privacy-section">
                    <div className="privacy-content-container">
                    <div className="mt-3 normal-text">
                    At <span className="blue-text">SoftSmart 2.0</span>, we are passionate about transforming the way businesses leverage technology to achieve their goals. With over 25 years of experience in custom software development, we provide cutting-edge solutions that empower financial institutions and businesses to thrive in today's fast-paced digital world.  
                    </div>
                    <div className="blue-text mt-3">Who We Are</div>
                    <div className="mt-3 normal-text">
                    Founded on the principles of innovation and client-centric solutions, SoftSmart Inc. 2.0 has become a trusted partner for businesses looking to stay competitive. Our team consists of highly skilled software developers, product specialists, engineers, and business strategists, each committed to delivering top-quality solutions tailored to your unique needs.
                    </div>
                    <div className="blue-text mt-3">Our Mission</div>
                    <div className="mt-3 normal-text">
                    We are dedicated to helping businesses across various industries harness the power of technology through custom solutions that drive growth, efficiency, and success. From building intuitive mobile apps to crafting dynamic CRM systems and efficient admin panels, our mission is to bring your ideas to life with seamless, scalable software.
                    </div>
                    <div className="blue-text mt-4">What We Do</div>
                    <div className="mt-3 normal-text">
                    We specialize in offering a full range of software and app development services, including:
                    </div>
                    <div style={{display:"flex", justifyContent:"center"}}>
                    <ul className="mt-3 mb-4" style={{display:"flex", flexDirection:"column", gap:"10px", width:"90%"}}>  
                        <li className="normal-text"><span className="green-text">Custom CRM Systems: </span> Tailor-made solutions designed to optimize customer relationships and business operations.</li>
                        <li className="normal-text"><span className="green-text">Mobile App Development:</span> Sleek, user-friendly apps that deliver seamless experiences across all devices.</li>
                        <li className="normal-text"><span className="green-text">Admin Panels:</span> Powerful dashboards that give businesses complete control over their operations.</li>
                        <li className="normal-text"><span className="green-text">Automation Testing:</span> Ensuring your software runs smoothly through precision testing and quality assurance.</li>
                        <li className="normal-text"><span className="green-text">Nearshore Development:</span> Optimized development solutions that reduce costs while maintaining efficiency.</li>
                    </ul>
                    </div>
                    <div className="mt-3 normal-text">
                    Our expertise covers the complete project lifecycle, from conceptualization and design to development, testing, and deployment. We’re committed to delivering results that exceed expectations.
                    </div>

                    <div className="blue-text mt-4">Why Choose Us?</div>
                    <div className="mt-3 normal-text">
                    When you partner with <span className="blue-text">SoftSmart 2.0</span>, you gain access to:
                    </div>
                    <div style={{display:"flex", justifyContent:"center"}}>
                    <ul className="mt-3 mb-4" style={{display:"flex", flexDirection:"column", gap:"10px", width:"90%"}}>  
                        <li className="normal-text">A seasoned team with <span className="blue-text">25+ years</span> of industry expertise.</li>
                        <li className="normal-text">A track record of successfully completed projects across the financial sector and other industries.</li>
                        <li className="normal-text">IP address, browser type, operating system, and usage data about how you interact with our website or apps.</li>
                        <li className="normal-text">Tailor-made solutions that meet the unique needs of your business.</li>
                        <li className="normal-text">A commitment to quality, transparency, and client satisfaction.</li>
                    </ul>
                    </div>
                    <div className="mt-3 normal-text">
                    We pride ourselves on maintaining close communication with our clients to ensure that every project is aligned with their vision and objectives. Your success is our success.
                    </div>
                    <div className="blue-text mt-4">Our Vision</div>
                    <div className="mt-3 normal-text">
                    To be a leading technology solutions provider that empowers businesses to innovate, scale, and succeed through smart software solutions.
                    </div>
           
                    <div className="blue-text mt-4">Let’s Build the Future Together</div>
                    <div className="mt-3 normal-text">
                    At <span className="blue-text">SoftSmart Inc. 2.0</span>, we believe in delivering more than just software — we deliver results. We’re excited to work with you to bring your ideas to life. Ready to take the next step? <span className="blue-text">Contact us today</span> and discover how we can help your business grow.
                    </div>

                    </div>
                 </div>
			</Layout>
		</div>
	);
};

export default About;
