import React, { useState } from "react";
import Layout from "../../components/Layout/Layout";
import { Link } from "react-scroll";
import { Link as LinkTo } from "react-router-dom";
import logo from "../../images/logo.png";
import arrow from "../../images/arrow2.png";
import info from "../../images/info.png";
import { FaRegCircleXmark } from "react-icons/fa6";
import "../../App.css";

const Privacy = () => {
	
	return (
		<div className="homepage-principal-container">
			<Layout>
				<div className="header-principal-container">
					<div className="header-container">
						<div>
							<img src={logo} alt="logo-softsmart" />
						</div>
					
					</div>
				</div>
				<div className="principal-hero-section3">
					<div className="hero-section">
						<div className="hero-section-subtitle">
                        Privacy Policy for
						</div>
						<h1>SoftSmart 2.0</h1>
					</div>
				</div>
                 <div className="principal-privacy-section">
                    <div className="privacy-content-container">
                    <div className="blue-text">Effective Date: 01/09/2024</div>
                    <div className="mt-3 normal-text">
                    At <span className="blue-text">SoftSmart 2.0</span>, we take your privacy seriously. This Privacy Policy outlines how we collect, use, share, and protect your information in relation to our software development, mobile app production, and related services for the financial sector. We are committed to maintaining the confidentiality and security of your personal data.  
                    </div>
                    <div className="mt-3 mb-4 green-text">
                    Please read this Privacy Policy carefully to understand how we handle your personal information.
                    </div>
                    <div className="blue-text"><span style={{marginRight:"20px"}}>1.</span>Who We Are</div>
                    <div className="mt-3 normal-text">
                    SoftSmart 2.0, a division of  <span className="blue-text">SoftSmart Systems Int. Inc.</span>, specializes in providing custom software and app development services tailored to the unique needs of financial institutions. With over 25 years of experience in the tech industry, we leverage cutting-edge technology to provide solutions such as automation testing, CRM systems, data dashboards, mobile apps, and nearshore development.  
                    </div>
                    <div className="blue-text mt-4"><span style={{marginRight:"20px"}}>2.</span>Information We Collect</div>
                    <div className="mt-3 normal-text">
                    We collect different types of personal information depending on the nature of your interaction with our services. This information includes, but is not limited to: 
                    </div>
                    <div style={{display:"flex", justifyContent:"center"}}>
                    <ul className="mt-3 mb-4" style={{display:"flex", flexDirection:"column", gap:"10px", width:"90%"}}>  
                        <li className="normal-text"><span className="green-text">Personal Identifiers:</span> Name, email address, mailing address, phone number.</li>
                        <li className="normal-text"><span className="green-text">Business Information:</span> Company name, position, financial data related to your business.</li>
                        <li className="normal-text"><span className="green-text">Technical Information:</span> IP address, browser type, operating system, and usage data about how you interact with our website or apps.</li>
                        <li className="normal-text"><span className="green-text">Project-related Data:</span> Information related to the projects we work on for you, including specifications, requirements, and preferences.</li>
                        <li className="normal-text"><span className="green-text">Payment Information:</span> Details related to billing, invoices, and payment methods.</li>
                    </ul>
                    </div>
                    <div className="green-text mt-4">We collect personal data directly from you through:</div>
                    <div style={{display:"flex", justifyContent:"center"}}>
                    <ul className="mt-3 mb-4" style={{display:"flex", flexDirection:"column", gap:"10px", width:"90%"}}>  
                        <li className="normal-text">Our website contact forms</li>
                        <li className="normal-text">Email correspondence</li>
                        <li className="normal-text">Meetings and consultations</li>
                        <li className="normal-text">Client onboarding and project initiation</li>
                    </ul>
                    </div>
                    <div className="blue-text mt-4"><span style={{marginRight:"20px"}}>3.</span>How We Use Your Information</div>
                    <div className="mt-3 normal-text">
                    SoftSmart 2.0 uses your information to provide our services effectively and maintain our professional relationship with you. Specific uses include:
                    </div>
                    <div style={{display:"flex", justifyContent:"center"}}>
                    <ul className="mt-3 mb-4" style={{display:"flex", flexDirection:"column", gap:"10px", width:"90%"}}>  
                        <li className="normal-text"><span className="green-text">Service Delivery:</span> To provide, develop, and improve our services, including software and app development.</li>
                        <li className="normal-text"><span className="green-text">Client Support:</span> To process payments and manage financial transactions.</li>
                        <li className="normal-text"><span className="green-text">Billing and Payments:</span> IP address, browser type, operating system, and usage data about how you interact with our website or apps.</li>
                        <li className="normal-text"><span className="green-text">Marketing and Promotions:</span> With your consent, to inform you about new services, offers, or updates related to your projects.</li>
                        <li className="normal-text"><span className="green-text">Compliance: </span> To comply with legal obligations and to protect the legal rights and interests of SoftSmart 2.0.</li>
                    </ul>
                    </div>
                    <div className="blue-text mt-4"><span style={{marginRight:"20px"}}>4.</span>Sharing Your Information</div>
                    <div className="mt-3 normal-text">
                    We only share your personal information under the following circumstances:
                    </div>
                    <div style={{display:"flex", justifyContent:"center"}}>
                    <ul className="mt-3 mb-4" style={{display:"flex", flexDirection:"column", gap:"10px", width:"90%"}}>  
                        <li className="normal-text"><span className="green-text">With Service Providers:</span> Third-party vendors who assist us in providing our services (e.g., hosting, payment processing, customer support). These vendors are bound by contractual obligations to protect your information and only use it for the purposes we specify.</li>
                        <li className="normal-text"><span className="green-text">Legal Requirements:</span> If required by law, regulation, or legal process, we may disclose your information to regulatory authorities, law enforcement, or other third parties.</li>
                        <li className="normal-text"><span className="green-text">Business Transfers:</span>  In the event of a merger, acquisition, or sale of company assets, we may transfer your information to the successor company.</li>
                    </ul>
                    </div>
                    <div className="blue-text mt-4"><span style={{marginRight:"20px"}}>5.</span> Data Security</div>
                    <div className="mt-3 normal-text">
                    We prioritize the security of your personal information and use various physical, technical, and administrative measures to protect it. These measures include:
                    </div>
                    <div style={{display:"flex", justifyContent:"center"}}>
                    <ul className="mt-3 mb-3" style={{display:"flex", flexDirection:"column", gap:"10px", width:"90%"}}>  
                        <li className="normal-text">Encryption of sensitive data.</li>
                        <li className="normal-text">Regular updates to security protocols.</li>
                        <li className="normal-text">Restricted access to personal data on a need-to-know basis.</li>
                        <li className="normal-text">Employee training on privacy and data protection.</li>
                    </ul>
                    </div>
                    <div className="mt-1 normal-text">
                    However, no security system is completely foolproof, and we cannot guarantee the absolute security of your information.
                    </div>
                    <div className="blue-text mt-4"><span style={{marginRight:"20px"}}>6.</span> Data Retention</div>
                    <div className="mt-3 normal-text">
                    We retain your personal data only for as long as necessary to fulfill the purposes for which it was collected, as outlined in this Privacy Policy, or to comply with legal, regulatory, or contractual obligations. Once your data is no longer needed, we will securely delete or anonymize it.
                    </div>
                    <div className="blue-text mt-4"><span style={{marginRight:"20px"}}>7.</span>Your Privacy Rights</div>
                    <div className="mt-3 normal-text">
                    You have rights regarding your personal information, including:
                    </div>
                    <div style={{display:"flex", justifyContent:"center"}}>
                    <ul className="mt-3 mb-4" style={{display:"flex", flexDirection:"column", gap:"10px", width:"90%"}}>  
                        <li className="normal-text"><span className="green-text">Access: </span> You can request a copy of the personal data we hold about you.</li>
                        <li className="normal-text"><span className="green-text">Correction: </span> If any information is inaccurate or incomplete, you have the right to request correction.</li>
                        <li className="normal-text"><span className="green-text">Deletion: </span> In certain circumstances, you may request that we delete your personal information.</li>
                        <li className="normal-text"><span className="green-text">Opt-Out: </span> You can opt-out of receiving promotional emails from us by following the unsubscribe instructions included in those emails.</li>
                    </ul>
                    </div>
                    <div className="mt-3 normal-text">
                    To exercise your rights, please contact us using the details provided in the “Contact Us” section below.
                    </div>

                    <div className="blue-text mt-4"><span style={{marginRight:"20px"}}>8.</span>Cookies and Tracking Technologies</div>
                    <div className="mt-3 normal-text">
                    We use cookies and similar tracking technologies to enhance your browsing experience on our website. These may include:
                    </div>
                    <div style={{display:"flex", justifyContent:"center"}}>
                    <ul className="mt-3 mb-4" style={{display:"flex", flexDirection:"column", gap:"10px", width:"90%"}}>  
                        <li className="normal-text"><span className="green-text">Essential Cookies: </span> Necessary for the website to function properly.</li>
                        <li className="normal-text"><span className="green-text">Performance Cookies: </span> Used to collect data on how users interact with the website, which helps us improve our services.</li>
                        <li className="normal-text"><span className="green-text">Marketing Cookies: </span> Used to show you relevant advertisements or to measure the effectiveness of our marketing campaigns.</li>
                    </ul>
                    </div>
                    <div className="mt-3 normal-text">
                    You can adjust your cookie preferences through your browser settings. Please note that disabling certain cookies may affect the functionality of our website.
                    </div>

                    <div className="blue-text mt-4"><span style={{marginRight:"20px"}}>9.</span>Third-Party Links</div>
                    <div className="mt-3 normal-text">
                    Our website may contain links to third-party websites. This Privacy Policy applies only to SoftSmart 2.0, and we are not responsible for the privacy practices of other websites. We encourage you to read the privacy policies of any third-party sites you visit.
                    </div>

                    <div className="blue-text mt-4"><span style={{marginRight:"20px"}}>10.</span>Children’s Privacy</div>
                    <div className="mt-3 normal-text">
                    Our services are intended for businesses and individuals aged 18 and over. We do not knowingly collect personal information from children under 18. If you believe we have collected information from a child, please contact us immediately so we can take appropriate action.
                    </div>

                    <div className="blue-text mt-4"><span style={{marginRight:"20px"}}>11.</span>International Data Transfers</div>
                    <div className="mt-3 normal-text">
                    If you are accessing our services from outside the United States, please be aware that your information may be transferred to, stored, or processed in the United States, where our servers are located. We take appropriate measures to ensure that international data transfers comply with applicable data protection laws.
                    </div>

                    <div className="blue-text mt-4"><span style={{marginRight:"20px"}}>12.</span>Updates to This Privacy Policy</div>
                    <div className="mt-3 normal-text">
                    We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. When we make changes, we will update the “Effective Date” at the top of this policy. We encourage you to review this policy periodically.
                    </div>

                    <div className="blue-text mt-4"><span style={{marginRight:"20px"}}>13.</span>Contact Us</div>
                    <div className="mt-3 normal-text">
                    If you have any questions or concerns about this Privacy Policy or how your personal information is handled, please contact us at:
                    </div>

                    <div className="blue-text mt-4">SoftSmart 2.0</div>
                    <div className="mt-3">
                    <div className="email-privacy">
                    <span className="green-text">Email: </span>
                    <LinkTo to="mailto:info@softsmartcorp.com" >info@softsmartcorp.com</LinkTo> <br />
                    <LinkTo to="https://softsmartinc.com/" target="_blank">https://softsmartinc.com/</LinkTo>
                    </div>
                    </div>

                    </div>
                 </div>
			</Layout>
		</div>
	);
};

export default Privacy;
