import React, { useState } from "react";
import Layout from "../../components/Layout/Layout";
import { Link } from "react-scroll";
import { Link as LinkTo } from "react-router-dom";
import logo from "../../images/logo.png";
import arrow from "../../images/arrow2.png";
import info from "../../images/info.png";
import { FaRegCircleXmark } from "react-icons/fa6";
import "../../App.css";

const DeleteAccount = () => {

    return (
        <div className="homepage-principal-container">
            <Layout>
                <div className="header-principal-container">
                    <div className="header-container">
                        <div>
                            <img src={logo} alt="logo-softsmart" />
                        </div>

                    </div>
                </div>
                <div className="principal-hero-section3">
                    <div className="hero-section">
                        <h1>Delete Account and/or Personal Information</h1>
                    </div>
                </div>
                <div className="principal-privacy-section">
                    <div className="privacy-content-container">
                        <div className="mt-3 normal-text">
                            At <span className="blue-text">SoftSmart 2.0</span>, we respect your privacy and are committed to providing transparent control over your personal data. If you wish to delete your account or request the removal of personal information, we have outlined the steps below to ensure a smooth and secure process.
                        </div>
                        <div className="blue-text mt-3">Request for Deletion</div>
                        <div className="mt-3 normal-text">
                            You have the right to request the deletion of your personal information that we hold, in compliance with applicable data protection laws such as GDPR and CCPA.
                        </div>
                        <div className="blue-text mt-4">What Happens When You Delete Your Account?</div>
                        <div className="mt-3 normal-text">
                            When you request to delete your account or personal data, the following will occur:
                        </div>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <ul className="mt-3 mb-4" style={{ display: "flex", flexDirection: "column", gap: "10px", width: "90%" }}>
                                <li className="normal-text"><span className="green-text">Permanent Removal:</span> All your personal data, including any associated information (such as account details, preferences, and usage data), will be permanently removed from our systems.</li>
                                <li className="normal-text"><span className="green-text">Irreversible Action:</span> Once your account is deleted, this action cannot be undone. You will lose access to any services, data, or content associated with your account.</li>
                                <li className="normal-text"><span className="green-text">Data Retention:</span> Certain non-personal, aggregate data may be retained for legal or security purposes in compliance with our Terms and Conditions.</li>
                            </ul>
                        </div>
                        <div className="blue-text mt-4">How to Request Deletion</div>
                        <div className="mt-3 normal-text">
                            To delete your account or request the removal of your personal data, please follow these steps:
                        </div>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <ul className="mt-3 mb-4" style={{ display: "flex", flexDirection: "column", gap: "10px", width: "90%" }}>
                                <li className="normal-text"><span className="green-text">Contact Us:</span> Submit a request through our <span className="blue-text">Contact Support</span>  page or send an email to <span className="blue-text">support@softsmartcorp.com</span>. Please include "Delete Account Request" or "Delete Personal Information" in the subject line.</li>
                                <li className="normal-text"><span className="green-text">Verification Process:</span> We will verify your identity to ensure that the request is coming from the rightful account owner. You may be required to provide additional information to confirm your request.</li>
                                <li className="normal-text"><span className="green-text">Processing Time:</span> Once your request is verified, we will process the deletion of your account and personal data within 30 days, in accordance with legal requirements.</li>
                            </ul>
                        </div>
                        <div className="blue-text mt-4">Important Notes</div>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <ul className="mt-3 mb-4" style={{ display: "flex", flexDirection: "column", gap: "10px", width: "90%" }}>
                                <li className="normal-text"> Deleting your account may affect your ability to use certain services or features provided by <span className="blue-text">SoftSmart Inc. 2.0.</span></li>
                                <li className="normal-text"> If you have any pending projects or services, you may want to finalize those before submitting your request to delete your account.</li>
                                <li className="normal-text"> If your account is linked to third-party services, you may need to contact those services separately to request the deletion of your data from their systems.</li>
                            </ul>
                        </div>
                        <div className="blue-text mt-4">Contact Us for Assistance</div>
                        <div className="mt-3 normal-text">
                            If you have any questions about the deletion process or need further assistance, please don't hesitate to reach out to our support team at <span className="blue-text">support@softsmartcorp.com</span> or visit our <span className="blue-text">Contact Support</span> page. SoftSmart Inc. 2.0 is committed to maintaining your trust by ensuring that your personal information is handled responsibly and securely. We’re here to assist you in managing your data at every step.
                        </div>
                        <div className="mt-3 normal-text">
                            <span className="blue-text">SoftSmart 2.0</span> is committed to maintaining your trust by ensuring that your personal information is handled responsibly and securely. We’re here to assist you in managing your data at every step.
                        </div>

                    </div>
                </div>
            </Layout>
        </div>
    );
};

export default DeleteAccount;
