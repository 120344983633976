import React, { useState } from "react";
import Layout from "../../components/Layout/Layout";
import { Link } from "react-scroll";
import { Link as LinkTo } from "react-router-dom";
import logo from "../../images/logo.png";
import arrow from "../../images/arrow2.png";
import info from "../../images/info.png";
import { FaRegCircleXmark } from "react-icons/fa6";
import "../../App.css";

const TermsAndConditions = () => {
	
	return (
		<div className="homepage-principal-container">
			<Layout>
				<div className="header-principal-container">
					<div className="header-container">
						<div>
							<img src={logo} alt="logo-softsmart" />
						</div>
					
					</div>
				</div>
				<div className="principal-hero-section4">
					<div className="hero-section">
						<h1>Terms and Conditions</h1>
					</div>
				</div>
                 <div className="principal-privacy-section">
                    <div className="privacy-content-container">
                    <div className="blue-text">Effective Date: 01/10/2024</div>
                    <div className="mt-3 normal-text">
                    Welcome to <span className="blue-text">SoftSmart 2.0</span>, we take your privacy seriously. This Privacy Policy outlines how we collect, use, share, and protect your information in relation to our software development, mobile app production, and related services for the financial sector. We are committed to maintaining the confidentiality and security of your personal data.  
                    </div>
                    <div className="blue-text mt-3"><span style={{marginRight:"20px"}}>1.</span>Aceptante of Terms</div>
                    <div className="mt-3 normal-text">
                    By accessing or using our website and services, you acknowledge that you have read, understood, and agree to these Terms. If you do not agree, you should not access or use our services.
                    </div>
                    <div className="blue-text mt-3"><span style={{marginRight:"20px"}}>2.</span>Services Provided</div>
                    <div className="mt-3 normal-text">
                    SoftSmart Inc. 2.0 specializes in custom software development, mobile app production, automation testing, data dashboards, CRM solutions, and nearshore development for the financial sector. The services provided are detailed in our website, proposals, and contracts.
                    </div>
                    <div className="mt-3 normal-text">
                    All project timelines, deliverables, and terms for specific services are outlined in separate agreements or contracts between SoftSmart Inc. 2.0 and the client.
                    </div>
                    <div className="blue-text mt-4"><span style={{marginRight:"20px"}}>3.</span>User Responsibilities</div>
                    <div className="mt-3 normal-text">
                    As a user, you agree to:
                    </div>
                    <div style={{display:"flex", justifyContent:"center"}}>
                    <ul className="mt-3 mb-4" style={{display:"flex", flexDirection:"column", gap:"10px", width:"90%"}}>  
                        <li className="normal-text"> Provide accurate, current, and complete information when required.</li>
                        <li className="normal-text"> Use our services for lawful purposes only.</li>
                        <li className="normal-text"> IP address, browser type, operating system, and usage data about how you interact with our website or apps.</li>
                        <li className="normal-text"> Not engage in any activities that could harm, damage, or impair the functioning of our website or services.</li>
                        <li className="normal-text"> Respect intellectual property rights, confidentiality agreements, and any contractual obligations between you and SoftSmart Inc. 2.0.</li>
                    </ul>
                    </div>
                    <div className="blue-text mt-4"><span style={{marginRight:"20px"}}>4.</span>Intellectual Property</div>
                    <div className="mt-3 normal-text">
                    All content, trademarks, logos, and designs found on the SoftSmart Inc. 2.0 website, including software, graphics, and text, are the exclusive property of SoftSmart Inc. 2.0 or our licensors. Any unauthorized use of these materials is strictly prohibited.
                    </div>
                    <div className="mt-3 normal-text">
                    You are granted a limited, non-exclusive license to use our website and services for your own internal purposes only. This license does not include the right to:
                    </div>
                    <div style={{display:"flex", justifyContent:"center"}}>
                    <ul className="mt-3 mb-4" style={{display:"flex", flexDirection:"column", gap:"10px", width:"90%"}}>  
                        <li className="normal-text"> Resell or commercially exploit our content or services.</li>
                        <li className="normal-text"> Reproduce, distribute, or publicly display any of our proprietary materials without express written permission.</li>
                    </ul>
                    </div>
                    <div className="blue-text mt-4"><span style={{marginRight:"20px"}}>5.</span>Third-Party Links</div>
                    <div className="mt-3 normal-text">
                    Our website may contain links to third-party websites or services that are not owned or controlled by SoftSmart Inc. 2.0. We are not responsible for the content or services of any linked third-party websites, and accessing them is at your own risk.
                    </div>

                    <div className="blue-text mt-4"><span style={{marginRight:"20px"}}>6.</span>Payment Terms</div>
                    <div className="mt-3 normal-text">
                    Unless otherwise agreed in a separate contract, payment terms for services provided by SoftSmart Inc. 2.0 are as follows:
                    </div>
                    <div style={{display:"flex", justifyContent:"center"}}>
                    <ul className="mt-3 mb-4" style={{display:"flex", flexDirection:"column", gap:"10px", width:"90%"}}>  
                        <li className="normal-text"> An initial deposit may be required before any work begins.</li>
                        <li className="normal-text"> Remaining payments will be invoiced according to project milestones or completion stages.</li>
                        <li className="normal-text"> Late payments may incur interest or result in suspension of services until payment is received.</li>
                    </ul>
                    </div>

                    <div className="blue-text mt-4"><span style={{marginRight:"20px"}}>7.</span>Confidentiality</div>
                    <div className="mt-3 normal-text">
                    Both parties agree to maintain confidentiality with respect to sensitive information shared during the course of the project. Confidential information includes, but is not limited to, proprietary software, business strategies, client data, and financial records. This obligation extends beyond the termination of any contract or project.
                    </div>

                    <div className="blue-text mt-4"><span style={{marginRight:"20px"}}>8.</span>Termination</div>
                    <div className="mt-3 normal-text">
                    SoftSmart Inc. 2.0 reserves the right to suspend or terminate access to its services at any time if there is a breach of these Terms, or for other reasons such as non-payment or misuse of the website.
                    </div>
                    <div className="mt-3 normal-text">
                    Upon termination, any outstanding payments must be made within 30 days, and any access to proprietary software or services will be revoked.
                    </div>

                    <div className="blue-text mt-4"><span style={{marginRight:"20px"}}>9.</span>Disclaimer of Warranties</div>
                    <div className="mt-3 normal-text">
                    Our website and services are provided on an "as-is" and "as-available" basis without warranties of any kind, whether express or implied. We do not guarantee that our services will be uninterrupted, error-free, or secure.
                    </div>
                    <div className="mt-3 normal-text">
                    SoftSmart Inc. 2.0 disclaims any responsibility for any damages or loss caused by system errors, network failures, or data breaches, though we will take reasonable steps to prevent such occurrences.
                    </div>

                    <div className="blue-text mt-4"><span style={{marginRight:"20px"}}>10.</span>Limitation of Liability</div>
                    <div className="mt-3 normal-text">
                    In no event shall SoftSmart Inc. 2.0, its officers, employees, or affiliates be liable for any direct, indirect, incidental, special, or consequential damages arising from your use of our website or services. This includes, but is not limited to, loss of profits, data, or other intangible losses, even if SoftSmart Inc. 2.0 has been advised of the possibility of such damages.
                    </div>

                    <div className="blue-text mt-4"><span style={{marginRight:"20px"}}>11.</span>Indemnification</div>
                    <div className="mt-3 normal-text">
                    You agree to indemnify and hold harmless SoftSmart Inc. 2.0 from any claims, liabilities, damages, losses, or expenses (including legal fees) arising out of your use of the website or services, or your breach of these Terms.
                    </div>

                    <div className="blue-text mt-4"><span style={{marginRight:"20px"}}>12.</span>Governing Law</div>
                    <div className="mt-3 normal-text">
                    These Terms are governed by and construed in accordance with the laws of the state of [Insert State], without regard to its conflict of law provisions. Any legal action or dispute arising from these Terms will be handled exclusively in the courts located in [Insert City], [Insert State].
                    </div>

                    <div className="blue-text mt-4"><span style={{marginRight:"20px"}}>13.</span>Changes to Terms</div>
                    <div className="mt-3 normal-text">
                    We reserve the right to update or modify these Terms at any time. Any changes will be posted on this page, and continued use of our services after such changes constitutes your acceptance of the new Terms.
                    </div>

                    <div className="blue-text mt-4"><span style={{marginRight:"20px"}}>14.</span>Contact Information</div>
                    <div className="mt-3 normal-text">
                    If you have any questions regarding these Terms and Conditions or any other aspect of our services, please contact us at:
                    </div>

                    <div className="blue-text mt-4">SoftSmart 2.0</div>
                    <div className="mt-3">
                    <div className="email-privacy">
                    <span className="green-text">Email: </span>
                    <LinkTo to="mailto:info@softsmartcorp.com" >info@softsmartcorp.com</LinkTo> <br />
                    <LinkTo to="https://softsmartinc.com/" target="_blank">https://softsmartinc.com/</LinkTo>
                    </div>
                    </div>

                    </div>
                 </div>
			</Layout>
		</div>
	);
};

export default TermsAndConditions;
